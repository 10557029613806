import { createAsyncThunk } from '@reduxjs/toolkit';

import { pageHistory } from 'src/services/pageHistory';

export type UpdateHistoryPayload = {
  page: number;
  productCode: number;
};

const getQueryForHistoryState = (page: number) => {
  // No need to do anything else if page not present in the browser, because it's always implicity page=1.
  return `${location.search.replace(/page=\d+/, `page=${page}`)}`;
};

export const updatePageHistory = createAsyncThunk(
  'products/updatePageHistory',
  async ({ page, productCode }: UpdateHistoryPayload) => {
    const query = getQueryForHistoryState(page);
    history.replaceState(null, '', `${location.pathname}${query}`);
    // If not manual then page jumps up and down with a race condition because browser tries to go back in a wrong place.
    history.scrollRestoration = 'manual';
    pageHistory?.set('lastViewedProduct', { productCode });
  },
);
