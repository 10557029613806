/* istanbul ignore file */

import type { ChangeEvent } from 'react';
import React from 'react';
import { RadioDial } from '@noths/polaris-client-ribbons-design-system';

import * as styles from './RadioButton.styles';

/** @deprecated do not interfere with RadioButton's styles from outside **/
export const classNames = {
  RADIO_BUTTON__LABEL_CLASS: 'radio_button__label',
  RADIO_BUTTON__LABEL_TEXT_CLASS: 'radio_button__label-text',
};

export type RadioButtonProps = {
  checked?: boolean;
  labelText: string;
  name?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
};

export const RadioButton = ({
  checked = false,
  labelText,
  name,
  onChange,
  value,
}: RadioButtonProps) => (
  <label className={classNames.RADIO_BUTTON__LABEL_CLASS} css={styles.radioButtonWrapper}>
    <RadioDial
      aria-label={labelText}
      checked={checked}
      name={name}
      onChange={onChange}
      value={value}
    />
    <span
      aria-hidden="true"
      className={classNames.RADIO_BUTTON__LABEL_TEXT_CLASS}
      css={styles.radioButtonLabelText}
    >
      {labelText}
    </span>
  </label>
);
