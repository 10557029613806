import { parseToNumberOrNull } from 'src/utils/parseToNumberOrNull';
import type { PriceRange } from './types';

export const rangeToRadioLabel = (priceRange: PriceRange, currencySymbol: string) => {
  return `${currencySymbol}${priceRange.min}${
    priceRange.max === null ? '+' : `-${currencySymbol}${priceRange.max}`
  }`;
};

export const rangeToRadioValue = ({ max, min }: PriceRange) => `${min}-${max === null ? '' : max}`;

export const radioValueToRange = (radioValue: string): PriceRange => {
  const [min, max] = radioValue.split('-');

  return {
    min: parseToNumberOrNull(min),
    max: parseToNumberOrNull(max),
  };
};

export const forceRangeAscending = (range: PriceRange) => {
  const rangeIsDescending = range.min !== null && range.max !== null && range.min > range.max;

  return rangeIsDescending
    ? { min: range.max, max: range.min }
    : { min: range.min, max: range.max };
};
