import { ALL_CATEGORIES_DEPTH_VALUE } from 'src/components/organisms/Filter/constants/filter';
import type { BrowseDataAPIFilter } from 'src/services/browse-data-api/types/BrowseDataAPIFilter';

export const splitTreeFilterOptionsByDepth = (filter: BrowseDataAPIFilter) => {
  const depth = filter.options.findIndex(({ active }) => active);

  if (depth === -1) {
    return {
      parentNodes: filter.options.map((option) => ({ ...option, depth: 1 })),
      childNodes: [],
      depth: ALL_CATEGORIES_DEPTH_VALUE,
    };
  } else {
    const parents = filter.options.slice(0, depth + 1);
    const children = filter.options.slice(depth + 1);

    return {
      parentNodes: parents.map((option, index) => {
        const depthValue = index === 0 ? ALL_CATEGORIES_DEPTH_VALUE : index;
        return { ...option, depth: depthValue };
      }),
      childNodes: children.map((option) => ({ ...option, depth: depth + 1 })),
      depth,
    };
  }
};
