import React from 'react';

import { PriceFilter } from 'src/components/molecules/PriceFilter/PriceFilter';
import { FilterUid } from 'src/components/organisms/Filter/constants/uids';
import type { FilterOptionsProps } from 'src/components/organisms/Filter/containers/FilterOptionsContainer.types';
import { TreeContainer } from 'src/components/organisms/Filter/containers/TreeContainer';
import type { ChangedFilterDetails } from 'src/components/organisms/Filter/types';
import { parseToNumberOrNull } from 'src/utils/parseToNumberOrNull';
import { CheckboxFilter } from './CheckboxFilter';
import { DeliveryZoneFilter } from './DeliveryZoneFilter';
import type { ExpandableContentTogglePayload } from './FilterOptionsExpandableContent';
import { RadioButtonFilter } from './RadioButtonFilter';

export const FilterOptions = ({
  onExpandableContentToggle,
  onFilterOptionChange,
  totalActiveOptions,
  totalFiltersApplied,
  ...restOfProps
}: FilterOptionsProps) => {
  const props = {
    ...restOfProps,
    onExpandableContentToggle: (payload: ExpandableContentTogglePayload) => {
      onExpandableContentToggle({
        ...payload,
        totalFiltersApplied,
        totalActiveOptions,
      });
    },
    onFilterOptionChange: (payload: ChangedFilterDetails) => {
      onFilterOptionChange({
        ...payload,
        totalFiltersApplied,
        totalActiveOptions,
      });
    },
  };

  if (props.filter.uid === FilterUid.Price) {
    return (
      <PriceFilter
        currencyCode={props.currencyCode}
        max={parseToNumberOrNull(props.filter.options[1].value as string)}
        min={parseToNumberOrNull(props.filter.options[0].value as string)}
        onChange={({ max, min }) => {
          props.onFilterOptionChange({
            uid: props.filter.uid,
            title: props.filter.title,
            options: [
              {
                uid: props.filter.options[0].uid,
                value: min,
                active: min !== null,
              },
              {
                uid: props.filter.options[1].uid,
                value: max,
                active: max !== null,
              },
            ],
          });
        }}
      />
    );
  }

  if (props.filter.uid === FilterUid.DeliveryZones) {
    return (
      <DeliveryZoneFilter
        filter={props.filter}
        onExpandableContentToggle={props.onExpandableContentToggle}
        onFilterOptionChange={props.onFilterOptionChange}
      />
    );
  }

  switch (props.filter.type) {
    case 'checkbox':
      return (
        <CheckboxFilter
          filter={props.filter}
          isExpanded={props.isExpanded}
          onExpandableContentToggle={props.onExpandableContentToggle}
          onFilterOptionChange={props.onFilterOptionChange}
        />
      );
    case 'radio':
      return (
        <RadioButtonFilter
          filter={props.filter}
          onFilterOptionChange={props.onFilterOptionChange}
        />
      );
    case 'tree':
      return (
        <TreeContainer
          filter={props.filter}
          onExpandableContentToggle={props.onExpandableContentToggle}
          onFilterOptionChange={props.onFilterOptionChange}
        />
      );
    default:
      return null;
  }
};
