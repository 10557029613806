/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { useWindowScroll } from 'react-use';
import { Breakpoint, grid, maxWidthContainer } from '@noths/polaris-client-ribbons-base';
import { Responsive, TextIcon } from '@noths/polaris-client-ribbons-design-system';

import ArrowUpward from 'src/assets/svg/arrowUpward.svg';
import * as styles from 'src/components/molecules/BackToTopButton/styles';
import { PRODUCT_CONTAINER_WRAPPER } from 'src/constants/elementIds';

export const VISIBILITY_MINIMUM_THRESHOLD = 2000;

const handleClick = () => {
  const topElement = document.getElementById(PRODUCT_CONTAINER_WRAPPER);

  if (topElement) {
    topElement.scrollIntoView({ behavior: 'smooth' });
  }
};

export const BackToTopButton = () => {
  const [visible, setVisible] = useState(false);
  const windowScroll = useWindowScroll();

  useEffect(() => {
    const pageHeight = document.body.scrollHeight;
    const visibilityScrollThreshold = Math.max(pageHeight / 2, VISIBILITY_MINIMUM_THRESHOLD);

    setVisible(windowScroll.y > visibilityScrollThreshold);
  }, [windowScroll.y]);

  return (
    <Responsive minBreakpoint={Breakpoint.XL}>
      <div css={[grid, maxWidthContainer, styles.backToTopWrapper]} data-is-visible={visible}>
        <TextIcon
          css={styles.backToTopButton}
          icon={ArrowUpward}
          iconHeight={24}
          iconWidth={24}
          onClick={handleClick}
        />
      </div>
    </Responsive>
  );
};
