import React from 'react';
import { LoadingSpinner } from '@noths/polaris-client-ribbons-design-system';

import * as styles from './styles';

export const ModalLoader = () => (
  <div css={styles.wrapper}>
    <LoadingSpinner css={styles.spinner} />
    <p css={styles.copy}>Loading</p>
  </div>
);
