import React from 'react';

import { RadioButtonGroup } from 'src/components/molecules/RadioButtonGroup/RadioButtonGroup';
import type { ChangedFilterDetails } from 'src/components/organisms/Filter/types';
import type { BrowseDataAPIFilter } from 'src/services/browse-data-api/types/BrowseDataAPIFilter';
import { getActiveFilterCountString } from './utils';

interface RadioButtonFilterProps {
  filter: BrowseDataAPIFilter;
  onFilterOptionChange: (filterOptionDetails: ChangedFilterDetails) => void;
}

export const onChange = (
  radioButtonValue: string,
  filter: BrowseDataAPIFilter,
  onFilterOptionChange: (filterOptionDetails: ChangedFilterDetails) => void,
) => {
  const option = filter.options.find((option) => option.value === radioButtonValue);

  onFilterOptionChange({
    title: filter.title,
    uid: filter.uid,
    options: [
      {
        active: !option?.active,
        title: option?.title,
        value: radioButtonValue,
      },
    ],
  });
};

export const RadioButtonFilter = ({ filter, onFilterOptionChange }: RadioButtonFilterProps) => {
  return (
    <RadioButtonGroup
      accessibleLabel={filter.title}
      hideLabel
      name={filter.uid}
      onChange={(radioButtonValue: string) =>
        onChange(radioButtonValue, filter, onFilterOptionChange)
      }
      radioButtonData={filter.options.map(({ active = false, activeCount, title, value }) => ({
        checked: active,
        labelText: title,
        value: value as string,
        secondaryLabelText: getActiveFilterCountString(activeCount),
      }))}
    />
  );
};
