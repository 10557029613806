import React from 'react';
import { useVisibilityObserver } from '@noths/polaris-client-react-hooks';
import { visuallyHidden } from '@noths/polaris-client-styles';

import * as elementIds from 'src/components/organisms/Filter/constants/elementIds';
import { FilterOptionsContainer } from 'src/components/organisms/Filter/containers/FilterOptionsContainer';
import * as styles from 'src/components/organisms/Filter/styles/FilterList.styles';
import type { BrowseDataAPIFilter } from 'src/services/browse-data-api/types/BrowseDataAPIFilter';

const VISIBILITY_THRESHOLD = 0.7;

interface Props {
  filter: BrowseDataAPIFilter;
  onFilterListItemVisibility: (filterUid: string) => void;
}

export const FilterListItem = ({ filter, onFilterListItemVisibility }: Props) => {
  const { observedElementRef: listItemRef } = useVisibilityObserver<HTMLLIElement>(
    () => onFilterListItemVisibility(filter.uid),
    { observerOptions: { threshold: VISIBILITY_THRESHOLD }, autoUnobserve: true },
  );
  const activeOptionsCount = filter.options.filter((option) => option.active).length;

  return (
    <li css={styles.listItem} data-testid={`listitem-${filter.title}`} ref={listItemRef}>
      <h3 css={styles.filterHeading}>
        {filter.title}
        {activeOptionsCount > 0 && (
          <>
            <span css={styles.filterCount} data-testid={elementIds.FILTER_LIST_ITEM_COUNT}>
              {activeOptionsCount}
            </span>
            <span css={visuallyHidden}> selected</span>
          </>
        )}
      </h3>
      <FilterOptionsContainer filter={filter} key={filter.uid} />
    </li>
  );
};
