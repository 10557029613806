import { connect } from 'react-redux';
import { selectFavouritesSummary } from '@noths/polaris-client-user-favourites';

import { ProductPages } from 'src/components/organisms/ProductPages/components/ProductPages';
import {
  selectLoadMoreProductsURL,
  selectLoadPreviousProductsURL,
} from 'src/components/organisms/ProductPages/modules/selectors';
import type {
  ProductPagesContainerDispatchProps,
  ProductPagesContainerStateProps,
} from 'src/components/organisms/ProductPages/types';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import {
  selectProductsByPage,
  selectTotalPages,
  selectTotalProducts,
} from 'src/redux/products/selectors';
import { loadPageOfProducts } from 'src/redux/products/thunks/loadPageOfProducts';
import type { AppThunkDispatch } from 'src/redux/store';

const mapStateToProps = (state: ReduxApplicationState): ProductPagesContainerStateProps => {
  return {
    isLoading: state.products.isLoading,
    loadPreviousURL: selectLoadPreviousProductsURL(state),
    loadMoreURL: selectLoadMoreProductsURL(state),
    pageLoading: state.products.pageLoading,
    productsByPage: selectProductsByPage(state),
    favouriteProductCodes: selectFavouritesSummary(state).productCodes,
    totalPages: selectTotalPages(state),
    totalProducts: selectTotalProducts(state),
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch): ProductPagesContainerDispatchProps => {
  return {
    onLoadPrevious: ({ page }: { page: number }) => {
      dispatch(loadPageOfProducts(page - 1));
    },
    onLoadMore: ({ page }: { page: number }) => {
      dispatch(loadPageOfProducts(page + 1));
    },
  };
};

export const ProductPagesContainer = connect(mapStateToProps, mapDispatchToProps)(ProductPages);
