import { createSelector } from '@reduxjs/toolkit';
import type { ParsedUrlQueryInput } from 'querystring';
import qs from 'querystring';

import type { FilterOptionsProps } from 'src/components/organisms/Filter/containers/FilterOptionsContainer.types';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectPathname } from 'src/redux/navigation/selectors';
import { selectRequestOptionsAsQueryObject } from 'src/redux/products/selectors';

const selectFilterUidProp = (_: ReduxApplicationState, { filter }: FilterOptionsProps) =>
  filter.uid;

const omit = (obj: ParsedUrlQueryInput, propertyName: string) => {
  const { [propertyName]: discard, ...newObj } = obj;

  return newObj;
};

export const selectRouterPathWithoutCurrentFilter = createSelector(
  selectPathname,
  selectRequestOptionsAsQueryObject,
  selectFilterUidProp,
  (pathname, query, filterUid) => {
    const queryString = qs.stringify({
      ...omit(query, filterUid),
    });
    const result = queryString.length > 0 ? `${pathname}?${queryString}` : pathname;
    return result;
  },
);
