import React from 'react';
import { grid } from '@noths/polaris-client-ribbons-base';
import { SkeletonCard } from '@noths/polaris-client-ribbons-design-system';

import { PRODUCT_CARD_GRID_ITEM_ID } from 'src/components/organisms/ProductPages/constants/elementIds';
import * as styles from './ProductList.styles';

const SKELETON_PRODUCT_LIST_ID = 'skeleton-product-list';
export const SKELETON_PRODUCT_LIST_TEST_ID = 'skeleton-product-list';

const PRODUCTS_PER_PAGE = 60;

export const SkeletonProductList = () => {
  const cards = Array(PRODUCTS_PER_PAGE).fill(null);

  return (
    <div
      css={[grid, styles.productListWrapper, styles.fadeIn]}
      data-testid={SKELETON_PRODUCT_LIST_TEST_ID}
      id={SKELETON_PRODUCT_LIST_ID}
    >
      {cards.map((_, index) => {
        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            css={styles.productCardGridItem}
            id={`${PRODUCT_CARD_GRID_ITEM_ID}-${index}`}
            key={`skeleton-${index}`}
          >
            <SkeletonCard productIndex={index} />
          </div>
        );
      })}
    </div>
  );
};
