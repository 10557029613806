import {
  fontSize,
  maxBreakpoints,
  minBreakpoints,
  theme,
} from '@noths/polaris-client-ribbons-base';
import { mediaQuery, pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { fonts, spacing, text } = theme;

export const errorContainer = css`
  display: flex;
  height: 100%;
  text-align: center;

  ${mediaQuery(
    { max: maxBreakpoints.S },
    css`
      width: 100vw;
      margin-top: ${pxToRem(spacing.lg)};
    `,
  )};
`;

export const errorContentWrapper = css`
  margin: auto;
  width: 65%;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      width: 75%;
    `,
  )};
`;

export const errorHeading = css`
  ${fontSize(text.HEADING.SM)};
  font-family: ${fonts.PRIMARY_REGULAR.family};
  margin-top: 0;
`;

export const errorContent = css`
  ${fontSize(text.BODY.MD)};
`;
