import { theme } from '@noths/polaris-client-ribbons-base';
import { pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { fonts, spacing } = theme;

export const wrapper = css`
  background-color: var(--neutral-black-10);
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const spinner = css`
  width: ${pxToRem(spacing.xxl)};
  height: ${pxToRem(spacing.xxl)};
`;

export const copy = css`
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
  margin-top: ${pxToRem(spacing.sm)};
`;
