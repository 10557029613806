import { css } from 'styled-components';

export const progressBar = css`
  background: var(--neutral-black-30);
  border-radius: 0.25rem;
  display: block;
  height: 0.5rem;
  margin: 0 auto 1.25rem;
  width: 11.25rem;
`;

export const progress = css`
  height: 100%;
  background: var(--neutral-black-base);
  border-radius: 0.25rem;
`;
