import { focusOld, maxBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery, pxToRem, stack } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing } = theme;

const nodeLink = css`
  text-decoration: none;
  line-height: 1.75;
  color: var(--neutral-black-base);
  margin: ${stack(spacing.sm)};
  transition: background-color 150ms linear;

  ${mediaQuery(
    { max: maxBreakpoints.M },
    css`
      margin: ${stack(spacing.lg)};
    `,
  )};

  &.focus-visible:focus {
    ${focusOld};
    border-radius: ${pxToRem(8)};
  }

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
      color: var(--brand-purple-base);
    }
  }

  @media (hover: none) {
    &:active {
      background-color: var(--brand-purple-30);
    }
  }
`;

export const parentNodeLink = css`
  ${nodeLink}
  display: flex;
  align-items: center;
  position: relative;
  left: 0.125rem;

  img {
    margin-right: ${pxToRem(spacing.md)};
  }

  &[data-is-active='true'] {
    font-weight: bold;
    display: block;
  }
`;

export const childNodeLink = css`
  ${nodeLink}
  padding-left: ${pxToRem(36)};
  display: block;
`;

export const list = css`
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  color: var(--neutral-black-base);
`;
