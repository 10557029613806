import { fontSize, minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { DRAWER_HEADER_CLASSNAME } from '@noths/polaris-client-ribbons-design-system';
import { inset, mediaQuery, pxToRem, stack } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

import { DESKTOP_FILTER_HEIGHT_CSS_VAR } from 'src/components/organisms/Filter/constants/cssVariables';

const { fonts, spacing, text } = theme;

export const smallScreenWrapper = css`
  display: block;
  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      display: none;
    `,
  )};
`;

export const filterListWrapper = css`
  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      height: 100vh;
      height: var(${DESKTOP_FILTER_HEIGHT_CSS_VAR});
      overflow-x: hidden;
      overflow-y: auto;
      ${
        /* Stop z-indexed items inside this wrapper overlapping the scrollbar in Chrome on MacOS */ ''
      }
      transform: translateZ(0);
    `,
  )};

  .${DRAWER_HEADER_CLASSNAME} {
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: ${inset(spacing.md, spacing.xl)};

    button {
      margin: 0;
    }
  }
`;

export const list = css`
  margin: 0;
  padding: ${inset(spacing.md, 40)};
  list-style: none;

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      padding: 0;
    `,
  )};
`;

export const listItem = css`
  padding: ${inset(0, 36)};
  border-bottom: solid 2px var(--neutral-black-30);

  &:first-child {
    padding: ${stack(36)};
  }

  &:last-child {
    border: none;
    padding-bottom: 0;
  }

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      padding: ${inset(0, spacing.base * 8)};

      &:first-child {
        padding: ${stack(spacing.base * 8)};
      }
    `,
  )};
`;

export const collapsibleListItem = css`
  padding-bottom: ${pxToRem(36)};
  border-bottom: solid ${pxToRem(2)} var(--neutral-black-30);

  &:first-child {
    padding-top: ${stack(36)};
  }

  &:last-child {
    border: none;
    padding-bottom: 0;
  }

  &[data-is-expanded='true'] {
    margin-bottom: ${pxToRem(36)};
  }

  &[data-is-expanded='false'] {
    border: none;
    margin-bottom: 0;
  }
`;

export const filterHeading = css`
  margin: 0 ${stack(spacing.lg)} ${stack(spacing.lg)};
  display: flex;
  align-items: center;
  ${fontSize(text.BODY.MD)};
  text-transform: capitalize;
`;

export const filterAccordionHeading = css`
  width: 100%;
  margin: 0;
`;

export const filterAccordionTitle = css`
  ${fontSize(text.BODY.MD)};
`;

export const filterArrowButton = css`
  width: 100%;
`;

export const filterCount = css`
  padding: 0 ${spacing.base}px;
  height: ${spacing.lg}px;
  width: ${spacing.lg}px;
  border-radius: 50%;
  background-color: var(--neutral-black-70);
  color: var(--neutral-white-base);
  text-align: center;
  line-height: ${pxToRem(20)};
  font-size: ${pxToRem(text.BODY.XS.fontSize)};
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
  margin: ${spacing.base}px 0 0 ${spacing.base}px;
`;
