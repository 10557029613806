import React from 'react';
import { TextButton } from '@noths/polaris-client-ribbons-design-system';

import * as styles from './ExpandableContentToggleButton.styles';

interface Props {
  elementId: string;
  isExpanded: boolean;
  toggleExpanded: VoidFunction;
}

export const ExpandableContentToggleButton = ({ elementId, isExpanded, toggleExpanded }: Props) => (
  <TextButton
    aria-controls={elementId}
    aria-expanded={isExpanded}
    css={styles.root}
    onClick={(evt) => {
      evt.stopPropagation();
      toggleExpanded();
    }}
  >
    Show {isExpanded ? 'less' : 'all'}
  </TextButton>
);
