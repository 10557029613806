import { batch, connect } from 'react-redux';
import { sendGAEvent } from '@noths/polaris-client-google-analytics';

import { FilterOptions } from 'src/components/organisms/Filter/components/FilterOptions/FilterOptions';
import { ALL_CATEGORIES_DEPTH_VALUE } from 'src/components/organisms/Filter/constants/filter';
import { FilterUid } from 'src/components/organisms/Filter/constants/uids';
import { getFilterOptionsWithoutNullActiveCounts } from 'src/components/organisms/Filter/modules/selectors/selectorHelpers';
import {
  selectTotalActiveOptions,
  selectTotalFiltersApplied,
} from 'src/components/organisms/Filter/modules/selectors/selectors';
import { actions as filterActions } from 'src/components/organisms/Filter/modules/slice';
import type { CustomFilterEvent } from 'src/components/organisms/Filter/tracking/events';
import type { FilterOptionDepth } from 'src/components/organisms/Filter/types';
import { actions as filterMenuActions } from 'src/components/organisms/FilterMenu/modules/slice';
import type { SortOptionValue } from 'src/components/organisms/FilterMenu/types';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { updateFilters, UpdateFiltersActionType } from 'src/redux/products/thunks/updateFilters';
import type { AppThunkDispatch } from 'src/redux/store';
import type { BrowseDataAPIFilter } from 'src/services/browse-data-api/types/BrowseDataAPIFilter';
import type {
  FilterOptionsContainerDispatchProps,
  FilterOptionsContainerStateProps,
} from './FilterOptionsContainer.types';

const getTreeFilterEventAction = (filterOptionDepth: FilterOptionDepth, filterTitle = '') => {
  if (filterOptionDepth.current === ALL_CATEGORIES_DEPTH_VALUE) {
    return `back to all ${filterTitle}`;
  }

  if (filterOptionDepth.previous > filterOptionDepth.current) {
    return `back to level ${filterOptionDepth.current} ${filterTitle}`;
  }

  return `select level ${filterOptionDepth.current} ${filterTitle}`;
};

export const mapStateToProps = (
  state: ReduxApplicationState,
  ownProps: { filter: BrowseDataAPIFilter; isExpanded?: boolean },
): FilterOptionsContainerStateProps => {
  return {
    currencyCode: state.products.currencyCode,
    filter: {
      ...ownProps.filter,
      options: getFilterOptionsWithoutNullActiveCounts(ownProps.filter.options),
    },
    isExpanded: ownProps.isExpanded,
    totalFiltersApplied: selectTotalFiltersApplied(state),
    totalActiveOptions: selectTotalActiveOptions(state),
  };
};

export const mapDispatchToProps = (
  dispatch: AppThunkDispatch,
): FilterOptionsContainerDispatchProps => ({
  onFilterOptionChange: ({ totalActiveOptions, totalFiltersApplied, ...changedFilter }) => {
    batch(() => {
      const sortOptionSelected = changedFilter.uid === FilterUid.Sort;

      if (sortOptionSelected) {
        /*
          When sort options are being used as filter options we need to ensure the sorting options state keeps in sync with the sort filter.
          We need to do that as the submission logic in the thunk looks at the original sorting options when sending params to the API and
          also keeps the UI in sync if the user suddenly goes to a desktop view and sees the original sort drop down (since this feature we are doing is only for MW & TA).
         */
        dispatch(
          filterMenuActions.selectSortOption(changedFilter.options[0].value as SortOptionValue),
        );
      }

      dispatch(filterActions.changeFilterOption(changedFilter));

      dispatch(
        updateFilters(
          sortOptionSelected ? UpdateFiltersActionType.SORT : UpdateFiltersActionType.FILTER,
        ),
      );
    });

    if (changedFilter.uid === FilterUid.Price) {
      const minPrice = changedFilter.options[0].value;
      const maxPrice = changedFilter.options[1].value;
      const eventLabels = [];

      if (minPrice !== null) {
        eventLabels.push(`price at least:${minPrice}`);
      }
      if (maxPrice !== null) {
        eventLabels.push(`price at most:${maxPrice}`);
      }

      sendGAEvent<CustomFilterEvent>({
        event: 'custom_event',
        event_category: 'Filters',
        event_action: `${eventLabels.length ? 'select' : 'de-select'} filter price`,
        event_label: eventLabels.join('|'),
      });
      return;
    }

    const changedOption = changedFilter.options[0];

    if (changedOption.depth) {
      sendGAEvent<CustomFilterEvent>({
        event: 'custom_event',
        event_category: 'Filters',
        event_action: getTreeFilterEventAction(changedOption.depth, changedFilter.title),
        event_label:
          changedOption.depth?.current === ALL_CATEGORIES_DEPTH_VALUE
            ? `number of categories: ${totalFiltersApplied}|number of filters: ${totalActiveOptions}`
            : changedOption.title!,
      });
    } else {
      sendGAEvent<CustomFilterEvent>({
        event: 'custom_event',
        event_category: 'Filters',
        event_action: `${changedOption.active ? 'select' : 'de-select'} filter ${
          changedFilter.title
        }`,
        event_label: changedOption.title!,
      });
    }
  },
  onExpandableContentToggle: ({
    filterTitle,
    isExpanded,
    totalActiveOptions,
    totalFiltersApplied,
  }) => {
    sendGAEvent({
      event: 'custom_event',
      event_category: 'Filters',
      event_action: `show ${isExpanded ? 'less' : 'all'} ${filterTitle}`,
      event_label: `number of categories: ${totalFiltersApplied}|number of filters: ${totalActiveOptions}`,
    });
  },
});

export const FilterOptionsContainer = connect(mapStateToProps, mapDispatchToProps)(FilterOptions);
