import { connect } from 'react-redux';

import { Tree } from 'src/components/organisms/Filter/components/FilterOptions/TreeFilter/Tree';
import { selectRouterPathWithoutCurrentFilter } from 'src/components/organisms/Filter/modules/selectors/treeSelectors';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import type { TreeContainerOwnProps, TreeContainerStateProps } from './TreeContainer.types';

export const mapStateToProps = (
  state: ReduxApplicationState,
  ownProps: TreeContainerOwnProps,
): TreeContainerStateProps => {
  return {
    // @ts-ignore
    pathWithoutCurrentFilter: selectRouterPathWithoutCurrentFilter(state, ownProps),
  };
};

export const TreeContainer = connect(mapStateToProps)(Tree);
