import React from 'react';
import { grid, maxWidthContainer } from '@noths/polaris-client-ribbons-base';
import { ProductCarousel } from '@noths/polaris-client-ribbons-design-system';
import { linkClickOpensInNewTab } from '@noths/polaris-client-utils';

import { PreventSwipeNavigation } from 'src/components/molecules/PreventSwipeNavigation/PreventSwipeNavigation';
import type { RecommendedProductsCarouselProps } from 'src/components/organisms/RecommendedProductsShelf/types';
import { transformDecoratedRecommendationsServiceProductToProductCarouselProduct } from 'src/components/organisms/RecommendedProductsShelf/utils/transformDecoratedRecommendationsServiceProductToProductCarouselProduct';
import { Placement } from 'src/constants/recommendations';
import * as styles from './RecommendedProductsCarousel.styles';

const OUT_OF_STOCK_LABEL = 'Sorry, this item is currently out of stock';

export const RecommendedProductsCarousel = ({
  currencyCode,
  onNavigation,
  onProductClick,
  onVisible,
  placementData,
  placementType,
  ...restOfProps
}: RecommendedProductsCarouselProps) => {
  const { placementStrategy = '', placementTitle, products } = placementData || {};
  const infoText = placementType === Placement.OutOfStock ? OUT_OF_STOCK_LABEL : undefined;
  const visible = products !== null;

  const transformedProducts =
    products?.map((data) =>
      transformDecoratedRecommendationsServiceProductToProductCarouselProduct(data, currencyCode),
    ) ?? [];

  return visible ? (
    <PreventSwipeNavigation css={[grid, maxWidthContainer]}>
      <div css={styles.recommendedProductsShelfOuter}>
        <div css={styles.recommendedProductsShelfInner}>
          <ProductCarousel
            infoText={infoText}
            lazyImages
            onNavigation={(swiped, control) => {
              onNavigation({
                navigationType: swiped ? 'Swipe' : 'Arrow click',
                navigationDirection: control === 'next' ? 'next' : 'previous',
                placementStrategy,
                placementType,
              });
            }}
            onProductClick={(evt, { index }) => {
              const linkOpensInSameWindow = !linkClickOpensInNewTab(evt);

              if (linkOpensInSameWindow) {
                evt.preventDefault();
              }

              const product = products[index];

              onProductClick({
                product,
                productIndex: index,
                placementStrategy,
                placementType,
              }).then(() => {
                if (linkOpensInSameWindow) {
                  window.location.assign(product.links[0]?.href || '');
                }
              });
            }}
            onVisible={() => {
              onVisible({ products, placementStrategy });
            }}
            products={transformedProducts}
            title={placementTitle}
            {...restOfProps}
          />
        </div>
      </div>
    </PreventSwipeNavigation>
  ) : null;
};
