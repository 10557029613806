import React from 'react';
import { SolidButton, TextButton } from '@noths/polaris-client-ribbons-design-system';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';

import * as styles from 'src/components/organisms/Filter/styles/FilterControls.styles';
import * as modalStyles from 'src/components/organisms/Filter/styles/FilterModal.styles';

export interface FilterControlsProps {
  isDisabled?: boolean;
  isLoading: boolean;
  modalCloseButtonText: string;
  onReset: (router: NextRouter) => void;
  onViewResults: VoidFunction;
  resetText: string;
}
export const FilterControls = ({
  isDisabled,
  isLoading,
  modalCloseButtonText,
  onReset,
  onViewResults,
  resetText,
}: FilterControlsProps) => {
  const router = useRouter();

  return (
    <div aria-hidden={isDisabled} css={modalStyles.modalSectionControls}>
      <div css={styles.resetButtonWrapper}>
        <TextButton
          disabled={isDisabled}
          onClick={(e) => {
            e.preventDefault();
            onReset(router);
          }}
        >
          {resetText}
        </TextButton>
      </div>
      <SolidButton disabled={isDisabled} fullWidth loading={isLoading} onClick={onViewResults}>
        {modalCloseButtonText}
      </SolidButton>
    </div>
  );
};
