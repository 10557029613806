import type { BreakpointName } from '@noths/polaris-client-ribbons-base';
import { isBreakpointSmallOrBelow } from '@noths/polaris-client-ribbons-base';

import {
  PAGINATION_WRAPPER_ID,
  PRODUCT_CARD_GRID_ITEM_ID,
} from 'src/components/organisms/ProductPages/constants/elementIds';

type NumberOfMaxRows = 1 | 2 | 3;

export const MAX_PRODUCTS_PER_ROW_MOBILE = 2;
export const MAX_PRODUCTS_PER_ROW_TABLET = 3;

const getMaxProductsPerRow = (breakpoint: BreakpointName) =>
  isBreakpointSmallOrBelow(breakpoint) ? MAX_PRODUCTS_PER_ROW_MOBILE : MAX_PRODUCTS_PER_ROW_TABLET;

const getNumberOfRowsOnSmallOrMediumBreakpoint = (
  breakpoint: BreakpointName,
  numberOfProducts: number,
): NumberOfMaxRows => {
  const maxProductsForOneRow = getMaxProductsPerRow(breakpoint);
  const maxProductsForTwoRows = maxProductsForOneRow * 2;

  if (numberOfProducts <= maxProductsForOneRow) {
    return 1;
  } else if (numberOfProducts <= maxProductsForTwoRows) {
    return 2;
  }

  return 3;
};

const getRowHeight = (row: NumberOfMaxRows) => {
  const formerRowProductTilePosition = row;
  const latterRowProductTilePosition = row + 1;

  const firstProductHeight =
    document.getElementById(`${PRODUCT_CARD_GRID_ITEM_ID}-${formerRowProductTilePosition}`)
      ?.offsetHeight ?? 0;

  const secondProductHeight =
    document.getElementById(`${PRODUCT_CARD_GRID_ITEM_ID}-${latterRowProductTilePosition}`)
      ?.offsetHeight ?? 0;
  // Return the value of the product card with greatest height, as it determines the row's height
  return Math.max(firstProductHeight, secondProductHeight);
};

export const getStickyPoint = (
  productsCount: number,
  breakpoint: BreakpointName,
): number | null => {
  const maxProductsPerRow = getMaxProductsPerRow(breakpoint);

  if (productsCount <= maxProductsPerRow) {
    return null;
  }

  // Account for the added height of the pagination section
  const paginationWrapperHeight = document.getElementById(PAGINATION_WRAPPER_ID)?.offsetHeight ?? 0;
  const numberOfRows = getNumberOfRowsOnSmallOrMediumBreakpoint(breakpoint, productsCount);

  return (Array.from({ length: numberOfRows }) as never[]).reduce(
    (accumulatedRowsHeight, _, row) => {
      return accumulatedRowsHeight + getRowHeight(row as NumberOfMaxRows);
    },
    paginationWrapperHeight,
  );
};

export const hasReachedStickyPoint = (scrollTop: number, stickyPoint: number | null) => {
  if (stickyPoint == null) {
    return false;
  }

  return scrollTop >= stickyPoint;
};
