import type { ProductCarouselProduct } from '@noths/polaris-client-ribbons-design-system';
import type { CurrencyCode, RecommendedProduct } from '@noths/polaris-dev-ts-types';

export const transformDecoratedRecommendationsServiceProductToProductCarouselProduct = (
  {
    images,
    linkURL: trackingURL,
    links,
    new: isNew,
    on_sale: onSale,
    pre_sale_prices,
    prices,
    purchasable: isPurchasable,
    sale_percentage,
    title,
  }: RecommendedProduct,
  currency: CurrencyCode,
): ProductCarouselProduct => ({
  title,
  src: images[0]?.href || '',
  href: links[0]?.href || '',
  alt: '',
  isNew,
  onSale,
  isPurchasable,
  price: prices?.find((prices) => prices.currency === currency),
  preSalePrice: pre_sale_prices?.find((price) => price.currency === currency),
  salePercentage: sale_percentage ?? undefined,
  trackingURL,
});
