/* istanbul ignore file */
import React, { useRef } from 'react';
import { isKeyboardTriggeredEvent } from '@noths/polaris-client-utils';

import type { ImperativeRef } from 'src/components/molecules/PopoverButton/PopoverButton';
import { PopoverButton } from 'src/components/molecules/PopoverButton/PopoverButton';
import { RadioButtonGroup } from 'src/components/molecules/RadioButtonGroup/RadioButtonGroup';
import * as copy from 'src/components/organisms/FilterMenu/constants/copy';
import * as styles from 'src/components/organisms/FilterMenu/styles/SortBy.styles';
import type {
  SelectedSortOption,
  SortOptionValue,
} from 'src/components/organisms/FilterMenu/types';
import type { BrowseDataAPISortingOption } from 'src/services/browse-data-api/types/BrowseDataAPISortingOption';

export interface SortByProps {
  isLoading: boolean;
  onSelectSortOption: (value: SortOptionValue) => void;
  selectedSortOption: SelectedSortOption;
  sortingOptions: BrowseDataAPISortingOption[];
}

export const SortBy = ({ onSelectSortOption, selectedSortOption, sortingOptions }: SortByProps) => {
  const popoverButtonRef = useRef<ImperativeRef | null>(null);
  const RadioButtons = (
    <RadioButtonGroup
      accessibleLabel={copy.SORT_BY}
      hideLabel
      name={'sort-by-radio'}
      onChange={(value, event) => {
        if (!isKeyboardTriggeredEvent(event)) {
          popoverButtonRef.current?.closeContent();
        }
        onSelectSortOption(value);
      }}
      radioButtonData={sortingOptions.map((option) => ({
        checked: option.id === selectedSortOption?.id,
        labelText: option.title,
        value: option.id,
      }))}
    />
  );

  const popoverButtonMarkup = (
    <span css={styles.popoverButtonText}>
      {copy.SORT_BY}
      <span css={styles.popoverButtonOptionValue}>{selectedSortOption?.title}</span>
    </span>
  );

  return (
    <>
      <PopoverButton buttonMarkup={popoverButtonMarkup} ref={popoverButtonRef}>
        <div css={styles.popoverContent}>{RadioButtons}</div>
      </PopoverButton>
    </>
  );
};
