import { createSelector } from '@reduxjs/toolkit';

import { selectPageType } from 'src/redux/navigation/selectors';
import { PageType } from 'src/types/navigation';

export const selectReferredBy = createSelector(selectPageType, (pageType) => {
  switch (pageType) {
    case PageType.Category:
    case PageType.AllProducts:
      return 'plp';
    case PageType.Search:
      return 'search';
    case PageType.Partner:
      return 'pplp';
    default:
      return undefined;
  }
});
