import { minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { applyKeyframe, mediaQuery, pxToRem, stack } from '@noths/polaris-client-styles';
import { css, keyframes } from 'styled-components';

const { spacing } = theme;

export const link = css`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;

export const productListWrapper = css`
  margin: ${stack(spacing.xl)};
`;

export const pageDivideWrapper = css`
  margin: 0 0 ${pxToRem(8)};

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      margin-bottom: ${pxToRem(40)};
    `,
  )};
`;

export const productCardGridItem = css`
  grid-column: auto / span 6;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      grid-column: auto / span 4;
    `,
  )};
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const fadeIn = css`
  animation: ${applyKeyframe(fadeInAnimation)} 400ms linear;
`;
