import {
  ICON_BUTTON__BUTTON_CLASS,
  ICON_BUTTON__ICON_CLASS,
} from '@noths/polaris-client-ribbons-design-system';
import { pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

import { ANIMATION_DURATION_IN_MS, POPOVER_CONTENT_CLASSNAME } from './constants';

const buttonHeight = '30px';

export const popoverButtonWrapper = css`
  position: relative;
  min-width: 16rem;

  .${ICON_BUTTON__BUTTON_CLASS} {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    background: none;
    border: none;
    width: 100%;
    border-radius: 10px;
    line-height: ${buttonHeight};
    padding: ${pxToRem(8)} ${pxToRem(16)} ${pxToRem(0)} ${pxToRem(16)};
  }

  .${ICON_BUTTON__ICON_CLASS} {
    margin-left: 5px;
    transform: rotate(180deg);
    transition: transform ${ANIMATION_DURATION_IN_MS}ms;
  }

  &[data-is-open='true'] {
    .${ICON_BUTTON__ICON_CLASS} {
      transform: rotate(0deg);
    }
  }

  .${POPOVER_CONTENT_CLASSNAME}-enter {
    opacity: 0;
  }
  .${POPOVER_CONTENT_CLASSNAME}-enter-active {
    opacity: 1;
    transition: opacity ${ANIMATION_DURATION_IN_MS}ms;
  }
  .${POPOVER_CONTENT_CLASSNAME}-exit {
    opacity: 1;
  }
  .${POPOVER_CONTENT_CLASSNAME}-exit-active {
    opacity: 0;
    transition: opacity ${ANIMATION_DURATION_IN_MS}ms;
  }
`;

export const popoverButtonContentContainer = css`
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 200;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.2);
`;
