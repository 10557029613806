import * as copy from 'src/components/organisms/FilterMenu/constants/copy';
import type { SelectedSortOption } from 'src/components/organisms/FilterMenu/types';

interface SlimFilterMenuLabels {
  accessibleFilterLabel: string;
  accessibleResetAllLabel: string;
  accessibleSortLabel: string;
  filterAndSortLabel: string;
  filterLabel: string;
  resetAllLabel: string;
}

export const getSlimFilterMenuLabels = (
  totalActiveOptions: number,
  selectedSortOption: SelectedSortOption,
): SlimFilterMenuLabels => {
  const resetAllLabel = copy.RESET_ALL;
  const filterLabel = copy.FILTER_CTA;
  const accessibleResetAllLabel = `Reset all filters`;
  const accessibleFilterLabel = `${copy.FILTER_CTA} options (${totalActiveOptions} selected)`;
  const filterAndSortLabel = copy.FILTER_AND_SORT_CTA;
  const accessibleSortLabel = `${copy.SORT_BY}${
    selectedSortOption ? ` (${selectedSortOption.title} selected)` : ''
  }`;

  return {
    filterLabel,
    filterAndSortLabel,
    accessibleFilterLabel,
    accessibleSortLabel,
    resetAllLabel,
    accessibleResetAllLabel,
  };
};
