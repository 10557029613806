import { minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { inset, mediaQuery, shadowUp } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { fonts, text } = theme;

export const errorGuard = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      ${shadowUp};
    `,
  )};
`;

export const heading = css`
  margin: ${inset(10, 32)};
  font-weight: normal;
  text-align: center;
  font-family: ${fonts.PRIMARY_REGULAR.family};
  font-size: ${text.HEADING.XS.fontSize}px;
  line-height: ${text.HEADING.XS.lineHeight}px;
`;
