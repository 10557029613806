import React from 'react';

import {
  errorContainer,
  errorContent,
  errorContentWrapper,
  errorHeading,
} from 'src/components/organisms/Filter/styles/ListingsApiError.styles';

export const ListingsApiError = () => (
  <div css={errorContainer}>
    <span css={errorContentWrapper}>
      <h1 css={errorHeading}>We&apos;re sorry</h1>
      <p css={errorContent}>
        Unfortunately, something has gone wrong.
        <br />
        Try again by clicking on the reset button below.
      </p>
    </span>
  </div>
);
