import { focusBorderOld } from '@noths/polaris-client-ribbons-base';
import { pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

export const backToTopWrapper = css`
  bottom: ${pxToRem(15)};
  height: ${pxToRem(48)};
  position: sticky;
  pointer-events: none;

  ${/*Visibility to prevent tabbing onto the button, after it is faded out with opacity */ ''}
  opacity: 0;
  visibility: hidden;

  transition: visibility 0.2s ease, opacity 0.4s ease, transform 0.2s ease,
    background-color 0.3s ease;

  &[data-is-visible='true'] {
    opacity: 1;
    visibility: visible;
  }
`;

export const backToTopButton = css`
  grid-column: -1;
  justify-self: end;
  margin-right: ${pxToRem(10)};
  border-radius: ${pxToRem(6)};
  padding: ${pxToRem(12)};
  width: ${pxToRem(48)};
  background-color: var(--neutral-white-base);
  box-shadow: 0 ${pxToRem(2)} ${pxToRem(4)} 0 rgba(0, 0, 0, 0.2);
  pointer-events: all;

  ${/* Prevent icon dragging when click-dragging the button*/ ''}
  img {
    pointer-events: none;
  }

  &:hover,
  &:active {
    background-color: var(--brand-purple-30);
  }

  &:active {
    transform: scale(0.83);
  }

  &.focus-visible:focus {
    ${/* override default focus styles */ ''}
    box-shadow: 0 ${pxToRem(2)} ${pxToRem(4)} 0 rgba(0, 0, 0, 0.2);

    &::before,
    &::after {
      content: '';
      position: absolute;

      ${
        /* Render the border away from the button, to create a gap between the button and border */ ''
      }
      top: ${pxToRem(-4)};
      bottom: ${pxToRem(-4)};
      left: ${pxToRem(-4)};
      right: ${pxToRem(-4)};
      border-radius: ${pxToRem(10)};
      ${focusBorderOld}
    }
  }
`;
