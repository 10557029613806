import { theme } from '@noths/polaris-client-ribbons-base';
import { pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing } = theme;

export const root = css`
  margin-top: ${pxToRem(spacing.sm)};
  text-align: left;
`;
