/* istanbul ignore file */
import { useEffect, useRef, useState } from 'react';

export const useDocumentScroll = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const previousScrollTop = useRef(0);

  function handleDocumentScroll() {
    const { scrollTop: currentScrollTop } = document.documentElement || document.body;

    setScrollTop((previousPosition) => {
      previousScrollTop.current = previousPosition;
      return currentScrollTop;
    });
  }

  useEffect(
    () => {
      window.addEventListener('scroll', handleDocumentScroll);

      return () => window.removeEventListener('scroll', handleDocumentScroll);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { scrollTop, previousScrollTop: previousScrollTop.current };
};
