import React from 'react';

import * as styles from './ProgressBar.styles';

const getPercentage = (part: number, total: number) => {
  return Math.floor((100 / total) * part);
};

const minMax = (number: number, min: number, max: number) => {
  return Math.min(max, Math.max(min, number));
};

export interface ProgressBarProps {
  part: number;
  total: number;
}

export const ProgressBar = ({ part, total }: ProgressBarProps) => {
  const percentage = getPercentage(part, total);
  const width = minMax(percentage, 0, 100);

  return (
    <div css={styles.progressBar} role="presentation">
      <div css={styles.progress} style={{ width: `${width}%` }} />
    </div>
  );
};
