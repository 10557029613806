import { pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const TRANSITION = '400ms ease-in-out';

export const expandableContentWrapper = css`
  overflow: hidden;
  transition: height ${TRANSITION};
  position: relative;

  &:after {
    content: '';

    pointer-events: none;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    height: ${pxToRem(32)};
    width: 100%;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255) 100%
    );
    transition: height ${TRANSITION};
  }

  &[aria-expanded='true'] {
    &:after {
      height: 0;
    }
  }
`;
