import type { ChangeEvent } from 'react';
import React from 'react';
import { visuallyHidden } from '@noths/polaris-client-styles';

import { RadioButton } from 'src/components/atoms/RadioButton/RadioButton';
import * as styles from './styles';

type RadioButtonData = {
  checked: boolean;
  labelText: string;
  secondaryLabelText?: string;
  value: string;
};

export interface RadioButtonGroupProps {
  accessibleLabel: string;
  accessibleLabelElementId?: string;
  accessibleLabelElementType?: keyof JSX.IntrinsicElements;
  hideLabel?: boolean;
  name: string;
  onChange: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
  radioButtonData: RadioButtonData[];
}

/**
 * The accessibleLabelElementType prop should be a string which represents the type of HTML element to use for the label e.g. 'h1'.
 * The accessibleLabelElementId prop should contain a string to customise the id attribute for the label element containing the accessible label.
 */
export const RadioButtonGroup = ({
  name,
  accessibleLabel,
  accessibleLabelElementType: AccessibleLabelElementTag = 'p',
  accessibleLabelElementId = `${name}-label`,
  hideLabel = false,
  onChange,
  radioButtonData,
}: RadioButtonGroupProps) => (
  <div aria-labelledby={accessibleLabelElementId} role="group">
    <span css={hideLabel ? visuallyHidden : undefined} id={accessibleLabelElementId}>
      <AccessibleLabelElementTag>{accessibleLabel}</AccessibleLabelElementTag>
    </span>
    {radioButtonData.map(({ checked, labelText, secondaryLabelText, value: radioButtonValue }) => (
      <div css={styles.radioWrapper} data-testid={radioButtonValue} key={radioButtonValue}>
        <RadioButton
          checked={checked}
          labelText={labelText}
          name={name}
          onChange={(e) => onChange(radioButtonValue, e)}
          value={radioButtonValue}
        />
        {secondaryLabelText && <span css={styles.secondaryLabel}>{secondaryLabelText}</span>}
      </div>
    ))}
  </div>
);
