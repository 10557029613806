import {
  selectRouterPathname,
  selectRouterQueryParameters,
} from '@noths/polaris-client-next-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { requestProducts } from './requestProducts';

export const refetchProducts = createAsyncThunk(
  'products/refetchProducts',
  async (_payload, { dispatch, getState }) => {
    const state = getState() as ReduxApplicationState;

    const queryParams = selectRouterQueryParameters(state);
    const path = selectRouterPathname(state);

    const productsQuery = {
      ...queryParams,
      path,
    };

    await dispatch(
      requestProducts({
        query: productsQuery,
      }),
    );
  },
);
