import type { ReactNode } from 'react';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useClickAway, useFirstMountState, useKey } from 'react-use';
import { TextIcon } from '@noths/polaris-client-ribbons-design-system';

import chevronUp from 'src/assets/svg/chevronUp.svg';
import { ANIMATION_DURATION_IN_MS, POPOVER_CONTENT_CLASSNAME } from './constants';
import * as styles from './styles';

export interface PopoverButtonProps {
  buttonMarkup: ReactNode | string;
  children: ReactNode;
}

export type ImperativeRef = {
  closeContent: () => void;
};

export const PopoverButton = React.forwardRef<ImperativeRef | null, PopoverButtonProps>(
  ({ buttonMarkup, children }: PopoverButtonProps, iconButtonRef) => {
    const [isContentOpen, setIsContentOpen] = useState(false);
    const [isPopupContentAnimating, setIsPopupContentAnimating] = useState(false);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const openContentButtonRef = useRef<HTMLButtonElement | null>(null);
    const closeContentButtonRef = useRef<HTMLButtonElement | null>(null);
    const openContent = () => setIsContentOpen(true);
    const closeContent = () => setIsContentOpen(false);
    const isFirstMount = useFirstMountState();

    useImperativeHandle(iconButtonRef, () => ({
      closeContent,
    }));

    useClickAway(contentRef, closeContent);
    useKey('Escape', closeContent);

    useEffect(() => {
      if (!isFirstMount) {
        const elementToFocus = isContentOpen ? closeContentButtonRef : openContentButtonRef;
        elementToFocus.current?.focus();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isContentOpen]);

    return (
      <div
        css={styles.popoverButtonWrapper}
        data-is-open={isPopupContentAnimating}
        data-testid="popover-container"
      >
        <TextIcon
          aria-expanded={isContentOpen}
          aria-haspopup="true"
          data-testid="open-content-button"
          icon={chevronUp}
          iconHeight={16}
          iconWidth={16}
          onClick={openContent}
          ref={openContentButtonRef}
        >
          {buttonMarkup}
        </TextIcon>
        <CSSTransition
          classNames={POPOVER_CONTENT_CLASSNAME}
          in={isContentOpen}
          onEnter={() => setIsPopupContentAnimating(true)}
          onExit={() => setIsPopupContentAnimating(false)}
          timeout={ANIMATION_DURATION_IN_MS}
          unmountOnExit
        >
          <div
            css={styles.popoverButtonContentContainer}
            data-testid="content-wrapper"
            ref={contentRef}
          >
            <TextIcon
              data-testid="close-content-button"
              icon={chevronUp}
              iconHeight={16}
              iconWidth={16}
              onClick={closeContent}
              ref={closeContentButtonRef}
            >
              {buttonMarkup}
            </TextIcon>
            <div data-testid="content-children-wrapper" role="menu">
              {children}
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  },
);
