import { connect } from 'react-redux';
import type { Dispatch } from '@reduxjs/toolkit';

import { SortBy } from 'src/components/organisms/FilterMenu/components/SortBy';
import {
  selectSelectedSortingOptionInSentenceCase,
  selectSortingOptionsInSentenceCase,
} from 'src/components/organisms/FilterMenu/modules/selectors';
import { actions as filterMenuActions } from 'src/components/organisms/FilterMenu/modules/slice';
import type { SortOptionValue } from 'src/components/organisms/FilterMenu/types';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { updateFilters, UpdateFiltersActionType } from 'src/redux/products/thunks/updateFilters';
import type { AppThunkDispatch } from 'src/redux/store';

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSelectSortOption: (value: SortOptionValue) => {
    dispatch(filterMenuActions.selectSortOption(value));
    (dispatch as AppThunkDispatch)(updateFilters(UpdateFiltersActionType.SORT));
  },
});

export const mapStateToProps = (state: ReduxApplicationState) => ({
  isLoading: state.products.isLoading,
  sortingOptions: selectSortingOptionsInSentenceCase(state),
  selectedSortOption: selectSelectedSortingOptionInSentenceCase(state),
});

export const SortByContainer = connect(mapStateToProps, mapDispatchToProps)(SortBy);
