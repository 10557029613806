import React, { useEffect } from 'react';
import { useWindowSize } from 'react-use';
import { theme } from '@noths/polaris-client-ribbons-base';
import { DrawerHeader } from '@noths/polaris-client-ribbons-design-system';
import { pxToRem } from '@noths/polaris-client-styles';
import { useRouter } from 'next/router';

import {
  FILTER_LIST_RESET,
  FILTER_MODAL_HEADING,
} from 'src/components/organisms/Filter/constants/copy';
import { DESKTOP_FILTER_HEIGHT_CSS_VAR } from 'src/components/organisms/Filter/constants/cssVariables';
import { FilterUid } from 'src/components/organisms/Filter/constants/uids';
import type { FilterListProps } from 'src/components/organisms/Filter/containers/FilterListContainer.types';
import * as styles from 'src/components/organisms/Filter/styles/FilterList.styles';
import * as modalStyles from 'src/components/organisms/Filter/styles/FilterModal.styles';
import { FilterControls } from './FilterControls';
import { FilterListItem } from './FilterListItem';
import { ListingsApiError } from './ListingsApiError';

const setFilterHeight = (windowHeight: number) =>
  document.documentElement.style.setProperty(
    DESKTOP_FILTER_HEIGHT_CSS_VAR,
    `calc(${windowHeight}px - ${pxToRem(theme.spacing.xl)})`,
  );

export const FilterList = ({
  error,
  filters,
  isLoading,
  modalCloseButtonText,
  onAllFiltersVisible,
  onCloseFilters,
  onDeliveryFilterVisible,
  onResetFilters,
  onViewResults,
  totalActiveOptions,
  totalFiltersApplied,
}: FilterListProps) => {
  const router = useRouter();
  const { height } = useWindowSize();

  useEffect(() => {
    setFilterHeight(height);
  }, [height]);

  const handleFilterVisible = (filterUid: string) => {
    const filterIndex = filters.findIndex(({ uid }) => uid === filterUid);
    const lastFilter = filterIndex === filters.length - 1;

    if (filterUid === FilterUid.Delivery) {
      onDeliveryFilterVisible();
    }
    if (lastFilter) {
      onAllFiltersVisible();
    }
  };

  return (
    <form
      css={[modalStyles.modalViewport, styles.filterListWrapper]}
      onSubmit={(e) => e.preventDefault()}
    >
      <div css={styles.smallScreenWrapper}>
        <DrawerHeader headingLevel="h2" onClose={onCloseFilters} title={FILTER_MODAL_HEADING} />
      </div>
      {error ? (
        <ListingsApiError />
      ) : (
        // this shows for everything, not just Desktop
        <ul css={[styles.list, modalStyles.modalScrollableContent]}>
          {filters.map((filter) => {
            if (filter.options.length === 0) {
              return null;
            }

            return (
              <FilterListItem
                filter={filter}
                key={filter.uid}
                onFilterListItemVisibility={handleFilterVisible}
              />
            );
          })}
        </ul>
      )}
      <div css={styles.smallScreenWrapper}>
        <FilterControls
          isLoading={isLoading}
          modalCloseButtonText={modalCloseButtonText}
          onReset={() =>
            onResetFilters(
              {
                totalFiltersApplied,
                totalActiveOptions,
              },
              router,
            )
          }
          onViewResults={() =>
            onViewResults({
              totalFiltersApplied,
              totalActiveOptions,
            })
          }
          resetText={FILTER_LIST_RESET}
        />
      </div>
    </form>
  );
};
