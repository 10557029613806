import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { refetchProducts } from 'src/redux/products/thunks/refetchProducts';
import {
  CATEGORY_NOT_FOUND_ERROR,
  PARTNER_NOT_FOUND_ERROR,
} from 'src/redux/products/thunks/thunkErrors';
import type { AppThunkDispatch } from 'src/redux/store';
import { ErrorGuard } from './ErrorGuard';

export const mapStateToProps = ({ filter, products }: ReduxApplicationState) => ({
  error: !!products.error && !filter.filterModal.isModalOpen ? products.error : null,
  notFoundErrorList: [CATEGORY_NOT_FOUND_ERROR.name, PARTNER_NOT_FOUND_ERROR.name],
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  onRetry: () => (dispatch as AppThunkDispatch)(refetchProducts()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ListingsApiErrorGuardContainer = connector(ErrorGuard);
