/* istanbul ignore file */

import React, { useRef } from 'react';
import { usePreventSwipeNavigation } from '@noths/polaris-client-react-hooks';

export interface PreventSwipeNavigationProps {
  children: React.ReactNode;
  tag?: keyof Pick<JSX.IntrinsicElements, 'div' | 'span'>;
}

// This component is used to prevent navigation swipes on iOS Safari.
// Namely, when swiping from far left or right, the user is prevented from navigating backwards/forwards in history.
export const PreventSwipeNavigation = ({
  tag: Component = 'div',
  ...props
}: PreventSwipeNavigationProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  usePreventSwipeNavigation(ref);

  return <Component {...props} ref={ref} />;
};
