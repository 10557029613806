import { useEffect, useMemo, useRef, useState } from 'react';
import { useFirstMountState } from 'react-use';
import { isBreakpointLargeOrAbove, useBreakpoint } from '@noths/polaris-client-ribbons-base';
import { isBrowser } from '@noths/polaris-client-utils';

import { useDocumentScroll } from 'src/hooks/useDocumentScroll/useDocumentScroll';
import { getStickyPoint, hasReachedStickyPoint } from './utils';

//If y is at the top of screen, renders the filter menu with the number of selected filter menu items
//Y is at the top of the screen when y is greater than 0 or equal to -0.5 (this is due to y being -0.5 when a quick filter is applied)
export const yIsAtTopOfScreen = (y: number) => y === -0.5 || y > 0;

interface Handlers {
  onFirstStickyVisibility?: VoidFunction;
  productsCount: number;
}

export const useStickyNav = (nav: HTMLDivElement | null, options: Handlers) => {
  const [isAtTopOfScreen, setAtTopOfScreen] = useState(true);
  const [isVisible, setVisible] = useState(true);
  const [isSticky, setSticky] = useState(false);
  const viewedAtLeastOnce = useRef(false);
  const { previousScrollTop, scrollTop } = useDocumentScroll();
  const isFirstMount = useFirstMountState();
  const breakpoint = useBreakpoint({ initializeWithLarge: true });

  /*
    Memoise to prevent calling "getStickyPoint" every render. Accessing `offsetHeight` on DOM nodes
    incurs a performance cost by causing layout thrashing (reflow).
  */
  const stickyPoint = useMemo(() => {
    const isBreakpointWithStickyMenu = !isBreakpointLargeOrAbove(breakpoint);

    /* 
      Wait until after 1st render, product cards are rendered at the correct screen size. 
    */
    if (isBrowser() && !isFirstMount && isBreakpointWithStickyMenu) {
      return getStickyPoint(options.productsCount, breakpoint);
    }

    return null;
    // Exclude "options.productsCount" to avoid unneccssary re-runs when loading additional pages
  }, [isFirstMount, breakpoint]);

  useEffect(() => {
    // Wait until the element has been instantiated before starting computations
    if (nav === null || !nav.offsetHeight) {
      return;
    }

    const isScrollingDown = previousScrollTop < scrollTop;
    const isScrollingUp = previousScrollTop > scrollTop;

    if (isScrollingDown && isSticky) {
      setVisible(false);
      setSticky(false);
    }

    const { y } = nav.getBoundingClientRect() || { y: 0 };

    if (yIsAtTopOfScreen(y)) {
      setVisible(true);
      setAtTopOfScreen(true);
    } else {
      setAtTopOfScreen(false);
    }

    /*
      If the user is scrolling up and the nav isn't already sticky,
      make it visible and sticky.
    */
    if (isScrollingUp && !isSticky && hasReachedStickyPoint(scrollTop, stickyPoint)) {
      setSticky(true);
      setVisible(true);

      if (!viewedAtLeastOnce.current) {
        viewedAtLeastOnce.current = true;

        options?.onFirstStickyVisibility?.();
      }
    }
  }, [scrollTop, previousScrollTop, nav, isSticky, options, stickyPoint]);

  return {
    isVisible,
    isSticky,
    isAtTopOfScreen,
  };
};
