import { connect } from 'react-redux';
import { sendGAEvent } from '@noths/polaris-client-google-analytics';
import type { Dispatch } from '@reduxjs/toolkit';

import { RecommendedProductsCarousel } from 'src/components/organisms/RecommendedProductsShelf/components/RecommendedProductsCarousel';
import type {
  RecommendedProductsShelfContainerDispatchProps,
  RecommendedProductsShelfContainerOwnProps,
  RecommendedProductsShelfContainerStateProps,
} from 'src/components/organisms/RecommendedProductsShelf/types';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { getProductClickTrackingEvent } from 'src/tracking/events/productClick';
import { getProductImpressionsTrackingEvent } from 'src/tracking/events/productImpression';
import { generateProductsForEvent } from 'src/utils/googleAnalytics';

const mapStateToProps = (
  { products, richRelevance }: ReduxApplicationState,
  { placementType }: RecommendedProductsShelfContainerOwnProps,
): RecommendedProductsShelfContainerStateProps => {
  return {
    currencyCode: products.currencyCode,
    placementData: richRelevance[placementType],
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { placementType }: RecommendedProductsShelfContainerOwnProps,
): RecommendedProductsShelfContainerDispatchProps => ({
  onNavigation: ({ navigationDirection, navigationType, placementStrategy, placementType }) => {
    const eventLabel = `${placementStrategy} : ${placementType}`;

    sendGAEvent({
      event: 'custom_event',
      event_category: 'Rich Relevance',
      event_action: `${navigationType} ${navigationDirection}`,
      event_label: eventLabel,
    });
  },
  onVisible: ({ placementStrategy, products }) => {
    sendGAEvent({ ecommerce: null });

    const trackingEvent = getProductImpressionsTrackingEvent({
      eventCategory: 'Rich Relevance',
      eventAction: 'Product Impressions',
      eventLabel: `${placementStrategy || ''} : ${placementType}`,
      impressions: generateProductsForEvent({
        products: products!,
        placementName: placementType,
        placementStrategy,
      }),
    });

    sendGAEvent(trackingEvent);
  },
  onProductClick: ({ placementStrategy, placementType, product, productIndex }) => {
    sendGAEvent({ ecommerce: null });

    const positionInList = productIndex + 1;
    const eventLabel = `${positionInList} : ${placementStrategy || ''} : ${placementType}`;

    return sendGAEvent(
      getProductClickTrackingEvent({
        eventCategory: 'Rich Relevance',
        eventLabel,
        recommendationsTrackingUrl: product.linkURL,
        actionFieldList: `product - rich relevance - ${placementType} - ${placementStrategy}`,
        products: generateProductsForEvent({
          products: [product!],
          placementName: placementType,
          placementStrategy,
        }),
      }),
    );
  },
});

export const RecommendedProductsShelfContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecommendedProductsCarousel);
