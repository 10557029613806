import { theme } from '@noths/polaris-client-ribbons-base';
import { css } from 'styled-components';

const { fonts } = theme;

export const dividerOuter = css`
  color: var(--neutral-black-base);
  font-family: ${fonts.PRIMARY_REGULAR.family}, sans-serif;
  font-size: 0.75rem;
  grid-column: 1 / -1;
  margin: 0;
  position: relative;
  text-align: center;
  z-index: 0;

  &::before {
    background: var(--neutral-black-30);
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: -1;
  }
`;

export const dividerInner = css`
  background: var(--neutral-white-base);
  display: inline-block;
  padding: 0 1.375rem;
`;
