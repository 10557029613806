import { minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery, pxToRem, stack } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing } = theme;

export const recommendedProductsShelfOuter = css`
  grid-column: span 12;

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 1 / -1;
    `,
  )}
`;

export const recommendedProductsShelfInner = css`
  padding: ${pxToRem(spacing.xl)} 0;
  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      padding: ${stack(spacing.xxl)} 0;
    `,
  )}
`;
