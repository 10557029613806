import { maxBreakpoints, minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery, pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing } = theme;

export const filterWrapper = css`
  ${mediaQuery(
    { max: maxBreakpoints.M },
    css`
      display: none;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      position: sticky;
      top: ${pxToRem(spacing.xl)};
    `,
  )};
`;

export const drawerContentContainer = css`
  background-color: var(--neutral-white-base);
`;
