import { fontSize, minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery, stack } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { text } = theme;

export const radioWrapper = css`
  display: flex;
`;

export const secondaryLabel = css`
  flex-grow: 1;
  margin: ${stack(22)};
  align-self: flex-end;
  text-align: right;
  ${fontSize(text.BODY.SM)};
  color: var(--neutral-black-70);

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      margin: ${stack(13)};
    `,
  )}
`;
