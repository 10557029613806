import type { ReactNode } from 'react';
import React, { useRef } from 'react';
import { useExpandableContent } from '@noths/polaris-client-react-hooks';

import { ExpandableContentToggleButton } from 'src/components/atoms/ExpandableContentToggleButton/ExpandableContentToggleButton';
import type { BrowseDataAPIFilter } from 'src/services/browse-data-api/types/BrowseDataAPIFilter';
import { expandableContentWrapper } from 'src/styles/useExpandableContent';

const MIN_HEIGHT = 240;

export interface ExpandableContentTogglePayload {
  filterTitle: string;
  isExpanded: boolean;
}

interface FilterOptionsExpandableContentProps {
  children: ReactNode;
  filter: BrowseDataAPIFilter;
  isExpandable: boolean;
  onExpandableContentToggle: (payload: ExpandableContentTogglePayload) => void;
}

export const FilterOptionsExpandableContent = ({
  children,
  filter,
  isExpandable,
  onExpandableContentToggle,
}: FilterOptionsExpandableContentProps) => {
  const elementWrapper = useRef<HTMLDivElement | null>(null);
  const elementWrapperId = `expandable_filter__element_wrapper__${filter.uid}`;

  const { height, isExpanded, toggleExpanded } = useExpandableContent({
    minHeight: MIN_HEIGHT,
    isExpandable,
    elementWrapper,
    observeSubtree: true,
  });

  const handleToggleExpanded = () => {
    toggleExpanded();
    onExpandableContentToggle({ isExpanded, filterTitle: filter.title });
  };

  return (
    <div>
      <div
        aria-expanded={isExpanded}
        css={isExpandable ? expandableContentWrapper : undefined}
        id={elementWrapperId}
        ref={elementWrapper}
        style={{ height }}
      >
        {children}
      </div>
      {isExpandable && (
        <ExpandableContentToggleButton
          elementId={elementWrapperId}
          isExpanded={isExpanded}
          toggleExpanded={handleToggleExpanded}
        />
      )}
    </div>
  );
};
