import {
  fontSize,
  maxBreakpoints,
  minBreakpoints,
  theme,
} from '@noths/polaris-client-ribbons-base';
import { BUTTON__CONTENT_CLASS } from '@noths/polaris-client-ribbons-design-system';
import { mediaQuery, pxToRem, shadowDown, stack } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { fonts, spacing, text } = theme;

export const FILTER_MENU_SMALL_TRANSITION_DURATION = 500;

/**
 * display: contents is needed for the sticky filter menu to work.
 */

export const smallScreenMenu = css`
  position: static;
  top: 0;
  z-index: 1;
  transform: translateY(-100%);
  transition: transform ${FILTER_MENU_SMALL_TRANSITION_DURATION / 1000}s;
  background: var(--neutral-white-base);
  ${shadowDown};
  display: contents;
  @supports not (display: contents) {
    display: inline;
  }

  &[data-is-visible='true'] {
    transform: translateY(0);
  }

  &[data-is-sticky='true'] {
    position: sticky;
  }

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      display: none;
    `,
  )};
`;

export const smallScreenCTAWrapper = css`
  display: flex;
  padding-top: ${pxToRem(6)};
  padding-bottom: ${pxToRem(8)};
`;

export const filterButton = css`
  display: flex;
  flex-wrap: nowrap;
  background-color: var(--neutral-white-base);
  border-radius: ${pxToRem(6)};
  margin-left: auto;
  margin-right: ${pxToRem(4)};
  border: none;
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
  ${fontSize(text.BODY.SM)};
  white-space: nowrap;
  color: var(--neutral-black-base);

  &:hover {
    background-color: var(--brand-purple-30);
    border-color: var(--brand-purple-base);
    color: var(--brand-purple-base);

    .iconFilter {
      --iconColor: var(--brand-purple-base);
    }
  }
`;

export const largeScreenMenu = css`
  display: none;

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      display: block;
      padding: ${stack(spacing.sm)};
      margin: ${stack(spacing.xxxxl)};
      ${shadowDown}
      background: white;
    `,
  )};
`;

export const resetButtonWrapper = css`
  width: ${pxToRem(105)};
  padding: ${pxToRem(spacing.base)} ${pxToRem(spacing.xs)};
`;

export const resetButtonText = css`
  ${fontSize(text.BODY.SM)};
`;

export const filterToolsWrapper = css`
  display: flex;
  align-items: center;
`;

export const filterLabel = css`
  margin: 0;
  width: ${pxToRem(110)};
  ${fontSize(text.BODY.MD)};
`;

export const totalProducts = css`
  margin: 0;
  ${fontSize(text.BODY.XS)};

  ${mediaQuery(
    { max: maxBreakpoints.M },
    css`
      flex-grow: 1;
      padding-left: ${pxToRem(16)};
      padding-top: ${pxToRem(4)};
    `,
  )};
`;

export const iconFilter = css`
  margin-left: ${pxToRem(8)};
  margin-right: ${pxToRem(8)};
  width: ${pxToRem(16)};
  height: ${pxToRem(16)};
`;

export const filterBar = css`
  display: flex;
  align-items: center;
  grid-column: 2 / span 10;
`;

export const sortByWrapper = css`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;

  .${BUTTON__CONTENT_CLASS} {
    justify-content: flex-end;
  }
`;
