/* istanbul ignore file */

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import { Filter } from 'src/components/organisms/Filter/components/Filter';
import { actions as filterActions } from 'src/components/organisms/Filter/modules/slice';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';

const mapStateToProps = ({ filter: { filterModal }, products }: ReduxApplicationState) => ({
  isModalOpen: filterModal.isModalOpen,
  error: products.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeFilterModal: () => dispatch(filterActions.closeModal()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const FilterContainer = connector(Filter);
