import type { MouseEvent } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { Breakpoint, useBreakpoint } from '@noths/polaris-client-ribbons-base';
import { toSentenceCase } from '@noths/polaris-client-utils';

import arrowBack from 'src/assets/svg/arrowBack.svg';
import type { TreeProps } from 'src/components/organisms/Filter/containers/TreeContainer.types';
import * as styles from 'src/components/organisms/Filter/styles/Tree.styles';
import type { ChangedFilterDetails } from 'src/components/organisms/Filter/types';
import type {
  BrowseDataAPIFilter,
  FilterOptionValue,
} from 'src/services/browse-data-api/types/BrowseDataAPIFilter';
import { FilterOptionsExpandableContent } from '../FilterOptionsExpandableContent';
import { splitTreeFilterOptionsByDepth } from './splitTreeFilterOptionsByDepth';

export const Tree = ({
  filter,
  onExpandableContentToggle,
  onFilterOptionChange,
  pathWithoutCurrentFilter,
}: TreeProps) => {
  const { childNodes, depth: initialDepth, parentNodes } = splitTreeFilterOptionsByDepth(filter);
  const [activeOptionDepth, setActiveOptionDepth] = useState(initialDepth);
  const prevDepthRef = useRef(initialDepth);
  const totalBranches = parentNodes.length + childNodes.length;
  const isOnSmallScreen = useBreakpoint({ initializeWithLarge: true }) === Breakpoint.S;
  const isExpandable = !isOnSmallScreen && totalBranches > 6;
  const isTreeRoot = parentNodes.every((x) => x.depth === 1);

  useEffect(() => {
    prevDepthRef.current = activeOptionDepth;
  }, [activeOptionDepth]);

  const onClick = (
    event: MouseEvent<HTMLAnchorElement>,
    value: FilterOptionValue,
    depth: number,
    filter: BrowseDataAPIFilter,
    onFilterOptionChange: (filterOptionDetails: ChangedFilterDetails) => void,
  ) => {
    event.preventDefault();

    setActiveOptionDepth(depth);

    const activatedOption = filter.options.find((option) => option.value === value);

    onFilterOptionChange({
      title: filter.title,
      uid: filter.uid,
      options: [
        {
          active: true,
          depth: { current: depth, previous: prevDepthRef.current },
          title: activatedOption?.title,
          value: value,
        },
      ],
    });
  };

  return (
    <FilterOptionsExpandableContent
      filter={filter}
      isExpandable={isExpandable}
      onExpandableContentToggle={onExpandableContentToggle}
    >
      <ul css={styles.list}>
        {parentNodes.map(({ active, depth, title, value }) => (
          <li key={value}>
            <a
              aria-label={`${title}${active ? ' (currently selected)' : ''}`}
              css={styles.parentNodeLink}
              data-is-active={active}
              href={`${pathWithoutCurrentFilter}&${filter.uid}=${value}`}
              onClick={(e) => onClick(e, value, depth, filter, onFilterOptionChange)}
            >
              {!active && !isOnSmallScreen && !isTreeRoot && (
                <img alt="" height={16} src={arrowBack} width={16} />
              )}
              {toSentenceCase(title)}
            </a>
            {active && childNodes.length > 0 && (
              <ul aria-label={`${toSentenceCase(title)} sub-categories`} css={styles.list}>
                {childNodes.map(({ depth, title, value }) => (
                  <li key={value}>
                    <a
                      css={styles.childNodeLink}
                      href={`${pathWithoutCurrentFilter}&${filter.uid}=${value}`}
                      onClick={(e) => onClick(e, value, depth, filter, onFilterOptionChange)}
                    >
                      {toSentenceCase(title)}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </FilterOptionsExpandableContent>
  );
};
