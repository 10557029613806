import { fontSize, minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { applyKeyframe, mediaQuery, pxToRem } from '@noths/polaris-client-styles';
import { css, keyframes } from 'styled-components';

const { fonts, spacing, text } = theme;

export const wrapper = css`
  position: relative;

  grid-column: span 12;

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 4 / span 8;
    `,
  )}
`;

export const expandedResultsMessage = css`
  grid-column: 1 / -1;
  margin: 0 ${pxToRem(spacing.xxl)};
  text-align: center;
  font-family: ${fonts.PRIMARY_REGULAR.family};
  font-weight: ${fonts.PRIMARY_REGULAR.weight};
  ${fontSize(text.BODY.MD)};
  color: var(--neutral-black-base);

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      margin: 0 0 -${pxToRem(spacing.xxxxl)};
    `,
  )};
`;

export const loadPageWrapper = css`
  margin-bottom: ${pxToRem(spacing.xxxl)};
  text-align: center;
`;

export const loadPreviousButton = css`
  margin-bottom: 0;
`;

export const progressWrapper = css`
  grid-column: 1 / -1;
  margin: 0 0 ${pxToRem(8)};
  text-align: center;

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      margin-bottom: ${pxToRem(40)};
    `,
  )};
`;

export const progressMessage = css`
  ${fontSize(text.BODY.XS)}
  margin: 0;
`;

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const fadeIn = css`
  animation: ${applyKeyframe(fadeInAnimation)} 400ms linear;
`;
