import { getProductIdFromProductCode } from '@noths/polaris-client-utils';

export const PRODUCT_ID_DATA_ATTRIBUTE = 'data-productid';

export const getProductCardNodeById = (id: number): HTMLElement | null => {
  return document.querySelector(`[${PRODUCT_ID_DATA_ATTRIBUTE}="${id}"]`) || null;
};

export const getProductCardNodeByCode = (code: number): HTMLElement | null => {
  const productId = getProductIdFromProductCode(code);

  return getProductCardNodeById(productId);
};

const isNodeAboveViewableArea = (node: HTMLElement | Element) => {
  const rect = node.getBoundingClientRect();
  const scrollTop = window.scrollY;

  return rect.top > scrollTop;
};

export const scrollProductCardIntoView = (node: HTMLElement | Element | null): void => {
  if (!node) {
    return;
  }

  const isAbove = isNodeAboveViewableArea(node);

  node.scrollIntoView({
    behavior: isAbove ? 'auto' : 'smooth',
    block: isAbove ? 'start' : 'end',
  });
};
