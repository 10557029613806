import { fontSize, theme } from '@noths/polaris-client-ribbons-base';
import { pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

import { classNames } from 'src/components/atoms/RadioButton/RadioButton';

const { fonts, spacing, text } = theme;

export const popoverButtonText = css`
  ${fontSize(text.BODY.MD)};
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
`;

export const popoverButtonOptionValue = css`
  color: var(--neutral-black-base);
  margin-left: 0.3rem;
`;

export const popoverContent = css`
  padding: 1rem;

  .${classNames.RADIO_BUTTON__LABEL_CLASS} {
    text-align: right;

    .${classNames.RADIO_BUTTON__LABEL_TEXT_CLASS} {
      margin-right: ${pxToRem(spacing.sm)};
    }
  }
`;
