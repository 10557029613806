import React, { useEffect, useRef, useState } from 'react';
import { InputField, SolidButton } from '@noths/polaris-client-ribbons-design-system';
import { CurrencySymbol } from '@noths/polaris-dev-ts-types';

import { RadioButtonGroup } from 'src/components/molecules/RadioButtonGroup/RadioButtonGroup';
import { parseToNumberOrNull } from 'src/utils/parseToNumberOrNull';
import { PRESET_PRICE_RANGES } from './config';
import { inputContainer } from './styles';
import type { PriceFilterProps, PriceRange } from './types';
import {
  forceRangeAscending,
  radioValueToRange,
  rangeToRadioLabel,
  rangeToRadioValue,
} from './utils';

export const PriceFilter = ({
  currencyCode,
  max = null,
  min = null,
  onChange,
}: PriceFilterProps) => {
  const firstRender = useRef(true);
  const [filterRange, setFilterRange] = useState<PriceRange>({ min, max });
  const initialMinMaxMatchesRadioButton = PRESET_PRICE_RANGES.some(
    (range) => range.min === min && range.max === max,
  );
  const [checkedRadioButtonValue, setCheckedRadioButtonValue] = useState<null | string>(
    initialMinMaxMatchesRadioButton ? rangeToRadioValue({ min, max }) : null,
  );
  const [manualRange, setManualRange] = useState<PriceRange>(
    initialMinMaxMatchesRadioButton ? { min: null, max: null } : { min, max },
  );
  const [manualInputsModified, setManualInputsModified] = useState(false);

  useEffect(() => {
    if (!firstRender.current) {
      onChange(filterRange);
    }
  }, [filterRange]);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  useEffect(() => {
    if (min === null && max === null && filterRange.min !== null && filterRange.max !== null) {
      setFilterRange({ min: null, max: null });
      setManualRange({ min: null, max: null });
      setCheckedRadioButtonValue(null);
      setManualInputsModified(false);
    }
  }, [min, max]);

  return (
    <div>
      <RadioButtonGroup
        accessibleLabel="Price Ranges"
        hideLabel
        name="price-ranges"
        onChange={(value) => {
          setCheckedRadioButtonValue(value);
          setManualRange({ min: null, max: null });
          setManualInputsModified(false);

          const checkedRange = radioValueToRange(value);

          setFilterRange(checkedRange);
        }}
        radioButtonData={PRESET_PRICE_RANGES.map((range) => {
          const value = rangeToRadioValue(range);

          return {
            checked: checkedRadioButtonValue === value,
            labelText: rangeToRadioLabel(range, CurrencySymbol[currencyCode]),
            value,
          };
        })}
      />

      <span css={inputContainer}>
        <InputField
          autoComplete="off"
          id="min-price"
          inputMode="numeric"
          label="Minimum Price"
          labelHidden
          onChange={(e) => {
            const newMin = parseToNumberOrNull(e.target.value);

            if (newMin !== manualRange.max) {
              setManualInputsModified(true);
            }

            setManualRange({
              min: newMin,
              max: manualRange.max,
            });
          }}
          placeholder="Min"
          prefixSymbol={CurrencySymbol[currencyCode]}
          value={manualRange.min === null ? '' : String(manualRange.min)}
        />
        <InputField
          autoComplete="off"
          id="max-price"
          inputMode="numeric"
          label="Maximum Price"
          labelHidden
          onChange={(e) => {
            const newMax = parseToNumberOrNull(e.target.value);

            if (newMax !== manualRange.min) {
              setManualInputsModified(true);
            }

            setManualRange({
              min: manualRange.min,
              max: newMax,
            });
          }}
          placeholder="Max"
          prefixSymbol={CurrencySymbol[currencyCode]}
          value={manualRange.max === null ? '' : String(manualRange.max)}
        />
      </span>
      <br />
      {manualInputsModified && (
        <SolidButton
          fullWidth
          onClick={() => {
            const validRange = forceRangeAscending(manualRange);

            setManualRange(validRange);
            setFilterRange(validRange);
            setCheckedRadioButtonValue(null);
            setManualInputsModified(false);
          }}
        >
          Apply
        </SolidButton>
      )}
    </div>
  );
};
